import React, { Fragment } from 'react';
import reactLogo from './react.svg';
import tailwindLogo from './tailwind.svg';

class Footer extends React.Component {
    render() {
        return (
            <Fragment>
                <div className="mt-14 px-4">
                    <p className="text-gray-500 text-xs sm:text-base">
                        Gemaakt met <a className="font-bold" href="https://reactjs.org/" target="_blank" rel="noreferrer">React <img className="inline w-6" src={reactLogo} alt="React" /></a> en <a className="font-bold" href="https://tailwindcss.com/" target="_blank" rel="noreferrer">TailwindCSS <img className="inline w-6 ml-1" src={tailwindLogo} alt="TailwindCSS" /></a>
                    </p>
                </div>
            </Fragment>
        )
    }
}

export default Footer