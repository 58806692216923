import React, { Fragment } from 'react';
import data from './data.json';

class Video extends React.Component {
    
    constructor(props) {
        super(props);

        this.state = {
            videoId: ''
        }

        this.getRandomVideo = this.getRandomVideo.bind(this);
    }

    componentDidMount() {
        this.getRandomVideo();
    }

    getRandomVideo() {
        const keys = Object.keys(data)

        // Generate random index based on number of keys
        const randIndex = Math.floor(Math.random() * keys.length)

        // Select a key from the array of keys using the random index
        const randKey = keys[randIndex]

        // Use the key to get the corresponding name from the "names" object.
        const id = data[randKey]

        this.setState({
            videoId: id
        })
    }

    render() {
        return (
            <Fragment>
                <div className="mx-auto w-full md:w-10/12 lg:w-8/12 mb-10">
                    <div className="relative" style={{paddingTop: '56.25%'}}>
                        <iframe title="YouTube video" className="absolute inset-0 w-full h-full" src={ 'https://www.youtube-nocookie.com/embed/' + this.state.videoId } frameBorder="0"></iframe>
                    </div>
                </div>
                <div className="flex justify-center w-full">
                    <button className="bg-blue-500 hover:bg-blue-700 duration-200 text-white font-bold py-4 px-6 rounded" onClick={this.getRandomVideo}>
                        Andere video
                    </button>
                </div>
            </Fragment>
        )
    }
}

export default Video;