import Footer from './Footer';
import Video from './Video';

function App() {
  return (
    <div className="container mx-auto px-4 flex flex-wrap content-center justify-center h-screen">
      <h1 className="text-2xl md:text-4xl lg:text-6xl text-white font-semibold text-center mb-10">VonoxNL Video Generator</h1>
      <Video />
      <Footer />
    </div>
  );
}

export default App;
